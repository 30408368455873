import React from "react";
import { Row, Col, Image } from "react-bootstrap";

function Categories() {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <Image src="images/categories/c1.jpg" fluid />
        </Col>
        <Col>
          <Image src="images/categories/c2.jpg" fluid />
        </Col>
        <Col>
          <Image src="images/categories/c3.jpg" fluid />
        </Col>
        <Col>
          <Image src="images/categories/c4.jpg" fluid />
        </Col>
      </Row>
    </>
  );
}

export default Categories;
