import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";

function EzokAdvantage() {
  return (
    <>
      <Row className="py-5">
        <Col md={5}>
          <Image
            src="images/advantage/a1.jpg"
            fluid
            style={{ height: "90%" }}
          ></Image>
        </Col>
        <Col md={2}>
          <Card>
            <Card.Img variant="top" src="images/advantage/a2.jpg" />
            <Card.Body>
              <Card.Title style={{ color: "#dbc08a" }}>Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card>
            <Card.Img variant="top" src="images/advantage/a3.jpg" />
            <Card.Body>
              <Card.Title style={{ color: "#dbc08a" }}>Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card>
            <Card.Img variant="top" src="images/advantage/a4.jpg" />
            <Card.Body>
              <Card.Title style={{ color: "#dbc08a" }}>Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default EzokAdvantage;
