import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";

import ShopProvider from "./context/shopContext";
import Home from "./pages/Home";
import ProductsDetails from "./pages/ProductsDetails";
import Cart from "../src/components/Cart/Cart";

function App() {
  return (
    <ShopProvider>
      <Router>
        <Header />
        <Cart />

        <main>
          <Route path="/" component={Home} exact></Route>
          <Route path="/product/:id" component={ProductsDetails} exact></Route>
        </main>
      </Router>
    </ShopProvider>
  );
}

export default App;
