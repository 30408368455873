import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../Blogs/Blogs.css";

function Blogs() {
  return (
    <>
      <Row className="p-4" style={{ width: "75%", margin: "auto" }}>
        <Col>
          <Card>
            <Card.Img variant="top" src="images/blogs/b1.jpg" />
            <Card.Body>
              <Card.Title>
                We Love Helping People Feel Great About How They Look
              </Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Card.Text>
              <Link to="#" style={{ color: "black" }}>
                Read More&nbsp;
                <ArrowRight />
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="images/blogs/b2.jpg" />
            <Card.Body>
              <Card.Title>
                We Love Helping People Feel Great About How They Look
              </Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Card.Text>
              <Link to="#" style={{ color: "black" }}>
                Read More&nbsp;
                <ArrowRight />
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Blogs;
