import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Product({ product }) {
  console.log(product);
  return (
    <>
      <Card>
        <Card.Img variant="top" src={product.images[0].src} />
        <Card.Body style={{ background: "#e6e6e6" }}>
          <Row>
            <Col>
              <Card.Text className="m-0" style={{ fontFamily: "sans-serif" }}>
                {product.title}
              </Card.Text>
              <Card.Text className="m-0" style={{ fontFamily: "sans-serif" }}>
                <strong>{product.variants[0].price}</strong>
              </Card.Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to={`product/${product.id}`}
                className="btn btn-outline-dark px-1 py-0"
                style={{ borderRadius: "0", fontFamily: "sans-serif" }}
              >
                BUY NOW
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default Product;
