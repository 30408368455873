import React from "react";
import { Col, Row, Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../FooterTop/FooterTop.css";

function FooterTop() {
  return (
    <section style={{ background: "#e6e6e6" }}>
      <Row className="py-3 px-4" style={{ width: "70%", margin: "auto" }}>
        <Col>
          <Row className="ms-5">
            <Col>
              <p style={{ fontFamily: "sans-serif" }}>
                ₹200 Coupon For First Shopping
              </p>
            </Col>
          </Row>
          <Row className="ms-5">
            <Col>
              <h2>Get The Latest Deals</h2>
            </Col>
          </Row>
        </Col>
        <Col className="m-auto">
          <Form className="d-flex" style={{ justifyContent: "center" }}>
            <FormControl
              type="search"
              className="mr-2 input2"
              aria-label="Search"
            ></FormControl>
            <Link className="btn btn-outline-dark input2Button" to="/search">
              <strong>SUBSCRIBE</strong>
            </Link>
          </Form>
        </Col>
      </Row>
    </section>
  );
}

export default FooterTop;
