import React, { useContext } from "react";
import "../Cart/Cart.css";
import { ShopContext } from "../../context/shopContext";
import { Divider, Drawer } from "@material-ui/core";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Cart() {
  const { isCartOpen, closeCart, checkout } = useContext(ShopContext);

  return (
    <>
      <Drawer open={isCartOpen} onClose={closeCart} anchor="right">
        <Container className="py-4">
          <div className="d-flex flex-column mb-4">
            <h1>Cart</h1>
            <Divider style={{ background: "black" }} />
            {checkout.lineItems &&
              checkout.lineItems.map((item) => (
                <Row key={item.id}>
                  <Col>
                    <Image src={item.variant.image.src} fluid />
                  </Col>
                  <Col>
                    <p>{item.title}</p>

                    <p>{item.variant.title}</p>

                    <p>{item.quantity}</p>
                  </Col>
                  <Col>
                    <p>{item.variant.price}</p>
                  </Col>
                </Row>
              ))}
            <a href={checkout.webUrl} target="_blank">
              Checkout
            </a>
          </div>
        </Container>
      </Drawer>
    </>
  );
}

export default Cart;
