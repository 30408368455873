import React from "react";
import "../ShopByInstagram/ShopByInstagram.css";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";

function ShopByInstagram() {
  return (
    <section style={{ background: "#e6e6e6" }}>
      <Row className="text-center p-5">
        <h4 style={{ fontFamily: "sans-serif" }}>Shop By Instagram</h4>
      </Row>
      <Row className="py-2" style={{ width: "90%", margin: "auto" }}>
        <Col>
          <Image className="hoverE" src="images/instagram/1.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/2.jpg" fluid></Image>
        </Col>
        <Col>
          <Image
            src="images/instagram/3.jpg"
            fluid
            style={{ border: "2px solid black" }}
          ></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/4.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/5.jpg" fluid></Image>
        </Col>
      </Row>
      <Row className="py-2" style={{ width: "90%", margin: "auto" }}>
        <Col>
          <Image className="hoverE" src="images/instagram/6.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/7.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/8.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/9.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/10.jpg" fluid></Image>
        </Col>
      </Row>
      <Row className="text-center p-5">
        <Link
          style={{
            fontFamily: "sans-serif",
            textDecoration: "none",
            color: "black",
          }}
        >
          <h4>
            Explore more&nbsp;
            <ArrowRight />
          </h4>
        </Link>
      </Row>
    </section>
  );
}

export default ShopByInstagram;
