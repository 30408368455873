import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Product from "../Product/Product";

function ProductParent({ products }) {
  return (
    <>
      <Row className="my-5">
        <Col>
          <Row style={{ width: "80%", margin: "auto" }}>
            <Col className="text-center">
              <Image src="images/dividers/newarrivals.jpg" fluid />
            </Col>
          </Row>
          {!products ? (
            "Loading"
          ) : (
            <Row style={{ width: "80%", margin: "auto" }}>
              {products.slice(0, 2).map((product) => {
                return (
                  <Col key={product.id} md={6}>
                    <Product product={product} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
        <Col>
          <Image src="images/banners/b2s.jpg" fluid />
        </Col>
      </Row>
    </>
  );
}

export default ProductParent;
