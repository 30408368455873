import React, { useState } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import "../Carousal2/Carousal2.css";

function CarousalComponent2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section>
      <div className="p-5">
        <Row className="text-center p-5">
          <Col>
            <h4 style={{ fontFamily: "sans-serif" }}>Happy Customers</h4>
          </Col>
        </Row>
        <Carousel
          className="carousel2"
          activeIndex={index}
          onSelect={handleSelect}
          style={{ height: "50%" }}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/testimonial/1.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/testimonial/2.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/testimonial/3.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default CarousalComponent2;
