import React, { useContext, useEffect } from "react";
import { Image, Container } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import CarousalComponent from "../components/Carousal/CarousalComponent";
import CarousalComponent2 from "../components/Carousal2/CarousalComponent2";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Categories from "../components/Categories/Categories";
import ProductParent from "../components/ProductParent/ProductParent";
import ShopByInstagram from "../components/ShopByInstagram/ShopByInstagram";
import EzokAdvantage from "../components/EzokAdvantage/EzokAdvantage";
import Blogs from "../components/Blogs/Blogs";
import FooterTop from "../components/FooterTop/FooterTop";

function Home() {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <>
      {/* Carousal */}
      <CarousalComponent />
      <Container>
        <Jumbotrons />
        <Image src="images/dividers/dc.jpg" className="mt-2" fluid />
        <Categories />
      </Container>

      <ProductParent products={products} />
      <Container>
        <ShopByInstagram />
        <CarousalComponent2 />
      </Container>
      <EzokAdvantage />
      <Container>
        <Image src="images/dividers/blog.jpg" className="mt-2" fluid />
        <Blogs />
      </Container>
      <FooterTop />
      <Image src="images/footer/footer.jpg" fluid></Image>
    </>
  );
}

export default Home;
